import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import ArticlesSection from "../components/blog/ArticlesSection";
import SocialMediaSection from "../components/general/SocialMediaSection";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";

const NotFoundStyles = styled.div`
  text-align: center;
`;

export function Head() {
  return (
    <SearchEngineOptimization
      meta={{
        title: "404 - Not Found",
        robotsContent: "none",
      }}
    />
  );
}

export default function NotFoundPage({
  data: {
    blogs: { nodes: blogs },
    channels: { nodes: channels },
  },
}) {
  return (
    <NotFoundStyles>
      <h1>Sorry, we couldn't find what you were looking for.</h1>

      <h2>
        Perhaps you'd be interested in reading one of our latest articles?
      </h2>
      <ArticlesSection blogs={blogs} />

      <h2>Why not join us on social media?</h2>

      <SocialMediaSection channels={channels} />
    </NotFoundStyles>
  );
}

export const query = graphql`
  query {
    blogs: allSanityBlog(
      filter: { publicityStatus: { eq: "PUBLISHED" } }
      limit: 3
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      nodes {
        _updatedAt
        title
        description
        slug {
          current
        }
        blogCategory {
          title
          slug {
            current
          }
        }
        tags {
          slug {
            current
          }
        }
        textEditor {
          _key
          _type
          _rawRichText
        }
        image {
          image {
            asset {
              gatsbyImageData(
                height: 400
                layout: CONSTRAINED
                width: 400
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }

    channels: allSanityChannel {
      nodes {
        title
        url
        image {
          image {
            asset {
              gatsbyImageData(
                height: 400
                layout: CONSTRAINED
                width: 400
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
