import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Linka from "../utility/Linka";

const CardStyles = styled.div`
  .article {
    position: relative;
    box-shadow: 0 0 5px 1px rgb(100 100 100 / 10%);
    background-color: var(--white);
    height: 100%;

    a {
      color: var(--color3);
    }

    .gatsby-image-wrapper {
      height: 400px;
    }

    .category-label {
      background: var(--black);
      opacity: 0.9;
      color: var(--white);
      text-transform: uppercase;
      padding: 4px;

      position: absolute;
      top: 5px;
      left: 5px;
    }

    .label {
      width: 100%;

      .tags {
        margin: 10px;

        .tag {
          padding: 0 10px;
          color: var(--urls);
          overflow-wrap: break-word;
          display: inline-block;

          &:hover {
            color: var(--color3);
          }
        }
      }

      a p {
        padding: 10px;
      }

      .article-title {
        margin: 0;
        padding: 1rem;
        text-align: center;
      }
    }
  }
`;

const ArticleCard = ({ slug, image, title, description, category, tags }) => {
  return (
    <CardStyles>
      <div key={slug} className="article">
        <Linka to={`/${category.slug.current}/${slug}/`}>
          {image && (
            <GatsbyImage
              image={image.image.asset.gatsbyImageData}
              alt={title}
            />
          )}
        </Linka>

        <Linka to={`/${category.slug.current}/`}>
          <span className="category-label">{category.title}</span>
        </Linka>

        <div className="label">
          <div className="tags">
            {tags &&
              tags.map((tag, i) => (
                <Linka key={i} to={`/${tag.slug.current}/`} className="tag">
                  {tag.title}
                </Linka>
              ))}
          </div>
          <Linka to={`/${category.slug.current}/${slug}/`}>
            <h2 className="article-title">{title}</h2>
            <p>{description}</p>
          </Linka>
        </div>
      </div>
    </CardStyles>
  );
};

export default ArticleCard;
