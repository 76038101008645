import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import ArticleCard from "../cards/ArticleCard";

const ArticlesStyles = styled.div`
  padding: 5%;
  margin: 0;
  background-color: var(--tan);

  h2 {
    margin-top: 0;

    @media (max-width: 900px) {
      text-align: center;
    }
  }

  .articles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }

  @media (max-width: 350px) {
    .articles {
      display: block;
    }
  }

  button {
    margin-bottom: 2rem;
  }
`;

const ArticlesSection = ({
  blogs,
  isInfinite,
  limitPerSection = 8,
  header,
}) => {
  const [loadedBlogs, setLoadedBlogs] = useState([]);
  const [allBlogsLoaded, setAllBlogsLoaded] = useState(false);

  const loadRef = useRef();

  useEffect(() => {
    const handleObserver = ([target]) => {
      if (target.isIntersecting) {
        setLoadedBlogs(
          [...blogs].splice(0, loadedBlogs.length + limitPerSection),
        );
      }
    };

    const options = {
      root: null,
      rootMargin: "500px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (loadRef.current) {
      observer.observe(loadRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [blogs, limitPerSection, loadedBlogs.length]);

  useEffect(() => {
    if (isInfinite) {
      setLoadedBlogs([...blogs].splice(0, limitPerSection));
    } else {
      setLoadedBlogs(blogs);
    }
  }, [isInfinite, blogs, limitPerSection]);

  useEffect(() => {
    if (loadedBlogs.length >= blogs.length) {
      setAllBlogsLoaded(true);
    } else {
      setAllBlogsLoaded(false);
    }
  }, [loadedBlogs.length, blogs.length]);

  return (
    <ArticlesStyles>
      {header && <h2>BLOG</h2>}

      <div className="articles">
        {loadedBlogs.map((blog) => (
          <ArticleCard
            key={blog.title}
            slug={blog.slug.current}
            image={blog.image}
            title={blog.title}
            description={blog.description}
            category={blog.blogCategory}
            tags={blog.tags}
          />
        ))}
      </div>

      {isInfinite && !allBlogsLoaded && <div ref={loadRef}>...</div>}
    </ArticlesStyles>
  );
};

export default ArticlesSection;
