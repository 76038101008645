import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Linka from "../utility/Linka";

const VideoStyles = styled.div`
  padding: 5%;
  margin: 0;
  background-color: var(--tan);

  .social-media {
    svg {
      width: 36px;
      height: 36px;
      margin-right: 0;
    }

    @media (max-width: 900px) {
      h2 {
        text-align: center;
      }
    }
  }

  .channels {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;

    @media (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
    }


    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    a {
      position: relative;
    }

    .channel-title {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #ffffffa1;
      color: var(--black);
      font-size: 1rem;
      text-align: center;
      padding: 20px;
    }
  }
`;

const SocialMediaSection = ({ channels }) => {
  return (
    <VideoStyles>
      <div className="social-media">
      <h2>
          CONNECT
        </h2>
        <div className="channels">
          {channels.map((channel) => (
            <Linka
              key={channel.title}
              to={channel.url}
              className="channel"
              rel="noopener noreferrer"
              target="_blank"
              title={channel.title}
            >
              <GatsbyImage
                image={channel.image.image.asset.gatsbyImageData}
                alt={channel.title}
              />
            </Linka>
          ))}
        </div>
      </div>
    </VideoStyles>
  );
};

export default SocialMediaSection;
